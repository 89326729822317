<template>
  <div class="quick-table">
    <div class="table-header">
      <slot name="header" v-bind="exportData"/>
    </div>

    <slot name="top-tool">
      <el-row type="flex" justify="space-between" class="table-tools">
        <!--   左边工具 -->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="dir-left-wrap">
            <el-button
              v-if="showRefresh"
              class="margin-s-r"
              type=""
              @click="refresh"
            >刷新
            </el-button>
            <!--          icon="el-icon-refresh-right"-->
            <slot name="left-tool">
              <action-group
                v-if="tableTools && tableTools.actions.length > 0"
                :action-list="tableTools.actions"
                v-bind="tableTools.props"
                :param-data="{
                  _keyValues_:selectedResources,
                  filterData:filterData
                }"
                :params="{

                }"
                :key="filterKey"
                @refresh="refresh"
                class="margin-s-r"
              />
              <action-group
                v-if="batchActions && batchActions.actions.length > 0"
                :action-list="batchActions.actions"
                :param-data="{
                  _keyValues_:selectedResources,
                  filterData:filterData
                }"
                :params="{}"
                v-bind="batchActions.props"
                :key="filterKey"
                :more-name="'已选择' + selectedResources.length + '项'"
                :disabled="selectedResources.length == 0"
                @refresh="refresh"
                class="margin-s-r"
              />

            </slot>
          </div>

        </el-col>
        <!--    右边工具  -->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">

          <el-row type="flex" justify="end" class="">
            <slot name="right-tool"/>

            <el-dropdown v-if="showColumnSelector" :hide-on-click="false" class="margin-s-l">


              <span class="el-dropdown-link">
                    <el-button icon="el-icon-setting"/>
                 </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-checkbox-group
                    v-model="ableColumns"
                    :min="1"
                  >
                    <el-dropdown-item v-for="(column ,index) in columns" :key="column.name + index">
                      <el-checkbox

                        v-model="column.name"
                        :label="column.name"
                      >{{ column.title }}
                      </el-checkbox>
                    </el-dropdown-item>
                  </el-checkbox-group>
                </el-dropdown-menu>
              </template>

            </el-dropdown>
            <el-dropdown v-if="showSize" :hide-on-click="false" trigger="click" class="margin-s-l"
                         @command="handleSizeCommand">

                <span class="el-dropdown-link">
                    <el-button icon="el-icon-Operation"/></span>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="large" :class="{'dropdown-active':size === 'large'}">{{ __('Large') }}
                  </el-dropdown-item>
                  <el-dropdown-item command="default" :class="{'dropdown-active':size === 'default' || size === ''}">
                    {{ __('Default') }}
                  </el-dropdown-item>
                  <el-dropdown-item command="small" :class="{'dropdown-active':size === 'small'}">{{ __('Small') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>

            </el-dropdown>

            <!--            search && !isShowFilter 显示过滤器就隐藏快捷搜索-->
            <div v-if="search" :style="{width: search.width ? search.width:'350px' }">
              <el-input
                v-model="searchModel.value"
                :placeholder="search.placeholder"
                clearable
                @keyup.enter="handleQuickSearch"
                class="input-with-select"
              >
                <template #prepend v-if="search.typeList && search.typeList.length">
                  <el-select v-model="searchModel.type" :placeholder="search.selectPlaceholder" style="width: 115px">
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in  search.typeList"
                               :key="index"/>
                  </el-select>
                </template>
                <template #append>
                  <el-button icon="el-icon-Search" @click="handleQuickSearch"/>
                </template>
              </el-input>
            </div>

            <el-button
              v-if="showFilter"
              class="margin-s-l"
              icon="el-icon-Filter"
              @click="handleShowFilter"
            >
            </el-button>
          </el-row>

        </el-col>
      </el-row>

    </slot>

    <TableV2
      v-if="columns.length && virtualize"
      :key="key"
      ref="_table"
      v-loading="loading"
      :data="tableLists"
      :columns="columns"
      v-bind="attrs"
      :highlight-current-row="showSelection && selectionType === 'radio'"
      @selection-change="handleSelectionChange"
      @current-change="handleTableCurrentChange"
    ></TableV2>
    <el-table
      v-if="columns.length && !virtualize"
      :key="key"
      ref="_table"
      v-loading="loading"
      :data="tableLists"
      v-bind="attrs"
      :highlight-current-row="showSelection && selectionType === 'radio'"
      @selection-change="handleSelectionChange"
      @current-change="handleTableCurrentChange"
    >
      <el-table-column
        v-if="showSelection && selectionType === 'radio'"
        label=""
        width="100"
      >
        <template #default="scope">
          <el-radio-group v-model="radioSelection" @change="handleSelectionChange(scope.row)">
            <el-radio :label="scope.row._key.value"></el-radio>
          </el-radio-group>

        </template>
      </el-table-column>
      <!--    id-->
      <el-table-column
        v-if="showSelection  && selectionType === 'checkbox'"
        type="selection"
        width="55"
      />
      <!--    内容栏-->
      <template v-for="(col ,index ) in showColumns" :key="index">
        <quick-column
          :column="col"
          @refresh="refresh"
          @actionExecuted="refresh"
        />
      </template>

    </el-table>
    <div class="table-footer">
      <slot name="footer" v-bind="exportData"/>
      <div class="dir-right-nowrap main-between" style="padding-top:15px;">
        <el-pagination
          v-if="showPaginate"
          :current-page="currentPage"
          :total="total"
          v-bind="paginateProp"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />

      </div>

      <teleport :to="'#' + modalId" v-if="showFooterItem">
       <div class="dir-right-nowrap">
         <el-button @click="handleConfirm" style="margin-left:10px;"  v-if="showConfirmBtn" :size="size" type="primary">{{ __('Confirm') }} </el-button>
         <el-button @click="handleCancel"  v-if="showConfirmBtn" :size="size" >{{ __('Cancel') }} </el-button>
       </div>
      </teleport>



    </div>
  </div>
</template>

<script>
import ActionGroup from '@/components/ActionGroup'
import Sortable from "sortablejs";
import {computed, inject,onMounted,ref} from "vue";
import {useModal} from "@/components/Modal";
import TableV2 from "./TableV2";
export default {
  name: 'QuickTable',
  components: {
    ActionGroup,
    TableV2
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    visibleColumns: {
      type: [Array, Boolean],
      default: false
    },
    lists: {
      type: Array,
      default: () => []
    },
    tableSize: {
      type: String,
      default: ''
    },
    showSelection: {
      type: Boolean,
      default: false
    },
    paginate: {
      type: [Object, Boolean],
      default: false
    },
    batchActions: {
      type: [Object, Boolean],
      default: false
    },
    tableTools: {
      type: [Object, Boolean],
      default: false
    },
    showColumnSelector: {
      type: Boolean,
      default: false
    },
    showSize: {
      type: Boolean,
      default: false
    },
    showRefresh: {
      type: Boolean,
      default: false
    },
    showPaginate: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 15
    },
    /**
     * 延迟加载数据长度
     * */
    maxLength: {
      type: Number,
      default: 20
    },
    /**
     * checkbox,radio
     **/
    selectionType: {
      type: String,
      default: 'checkbox'
    },
    showConfirmBtn: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    filterData:{
      type: [Object, Boolean],
      default: false
    },
    /**
     * {typeList:[],defaultType:'',defaultValue:''}
     */
    search: {
      type: [Object, Boolean],
      default: false
    },
    isShowFilter: {
      type: Boolean,
      default: false
    },
    virtualize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key: 1,
      checkedColumns: [],
      ableColumns: [],
      multipleSelection: [],
      size: '',
      loading: false,
      tableLists: [],
      height: '700',
      radioSelection: 0,
      searchModel: {
        type: '',
        value: '',
      },
      pageLimit:15,
      filterKey:3,
    }
  },
  computed: {
    tableHeight() {
      return this.height
    },
    paginateProp() {
      let defaultProps = {
        'page-size': this.pageLimit,
      }
      const paginate = this.paginate
      if (paginate) {
        defaultProps = Object.assign( {},paginate.props,defaultProps)
      }
      return defaultProps
    },
    selectedResources() {
      const ids = []
      _.each(this.multipleSelection, row => {
        ids.push(row._key.value)
      })
      // console.log('--selectedResources--',this.multipleSelection,ids)
      return ids
    },
    exportData() {
      return {
        'columns': this.columns,
        'multipleSelection': this.multipleSelection
      }
    },
    showColumns() {
      // return this.columns
      if (this.ableColumns === false || (Array.isArray(this.ableColumns) && !this.ableColumns.length)) {
        return this.columns
      }

      return this.columns.filter(i => this.ableColumns.indexOf(i.name) >= 0 || (i.name == null && i.children.length > 0))
    },
    /**
     *
     */
    defaultAttrs() {
      return {
        'element-loading-text': 'Loading',
        'size': this.size
        // 'height': this.tableHeight
      }
    },

    attrs() {
      const attrs = this.$attrs
      return {
        ...attrs,
        ...this.defaultAttrs
      }
    }
  },
  watch: {
    filterData:{
      immediate: true,
      handler(newVal) {
        this.filterKey++
      }
    },
    search: {
      immediate: true,
      handler(newVal) {
        if (newVal.defaultType) {
          this.searchModel.type = newVal.defaultType
        }
        if (newVal.defaultValue) {
          this.searchModel.defaultValue = newVal.defaultValue
        }
      }
    },
    lists: {
      handler: function (lists) {
        this.tableLists = lists
        this.key++
      }
    },
    pageSize: {
      handler: function (newVal) {
        this.pageLimit = newVal
      }
    }
  },
  mounted() {
    if (this.lists.length > this.maxLength) {
      this.loading = true
      setTimeout(() => {
        this.tableLists = this.lists
        this.$nextTick(() => {
          this.loading = false
        })
      }, 50)
    } else {
      this.tableLists = this.lists
    }


    this.pageLimit = this.pageSize ? this.pageSize:15
    if (this.paginate && this.paginate.props['page-size']) {
      this.pageLimit = this.paginate.props['page-size']
    }

    this.initSort();
  },
  created() {
    this.$watch('visibleColumns', function (visibleColumns) {
      if (Array.isArray(visibleColumns) && visibleColumns.length) {
        this.ableColumns = visibleColumns
      } else {
        const arr = []
        _.each(this.columns, column => {
          arr.push(column.name)
        })
        this.ableColumns = arr
      }
    }, {
      immediate: true
    })

    this.size = this.tableSize
  },
  setup(props, {emit}){

    const {currentModal} = useModal();
    const modalId = computed(() => {
      if (currentModal && currentModal.modalId) {
        return currentModal.modalId;
      }
      return "app";
    });

    const showFooterItem = ref(false)

    onMounted(() => {
      showFooterItem.value = true
      console.log('-----------modalId',modalId.value)
    })

    return {
      showFooterItem,
      modalId,
    }
  },
  methods: {
    initSort() {
      return
      const el = document.querySelector('.quick-table  .el-scrollbar__view > table > tbody')
      if(!el){
        return
      }
      // 根据具体需求配置options配置项
      const sortable = new Sortable(el, {
        draggable: ".el-table__row",
        handle: ".qk-drag",
        // filter: ".ignore-elements",
        preventOnFilter: false,
        onEnd: (evt) => { // 监听拖动结束事件
          let tableLists = this.tableLists
          const currRow = tableLists.splice(evt.oldIndex, 1)[0]
          tableLists.splice(evt.newIndex, 0, currRow)
          this.tableLists = tableLists
          this.key++
          this.$nextTick(() => {
            this.initSort()
          })
        }
      })
    },
    /**
     * 快捷搜索
     */
    handleQuickSearch() {
      this.$emit('quick-search', this.searchModel)
    },
    /**
     * 设置表格大小
     */
    handleSizeCommand(size) {
      this.size = size
    },
    /**
     * 设置每页显示数量
     */
    handleSizeChange(val) {
      this.$emit('size-change', val)
    },

    /**
     * 显示第几页
     * */
    handleCurrentChange(val) {
      this.$emit('page-change', val)
    },
    toggleRowExpansion() {
      // console.log('toggleRowExpansion--->', this.$refs._table)
      // this.$refs._table.toggleRowExpansion('149', true)
    },

    cellClick(row, column, cell, event) {
      // row.number = {}
      // this.$refs._table.toggleRowExpansion(row,true)
      // console.log('单元格被点击触发',row, column, cell, event)
    },
    /**
     * 刷新事件
     */
    refresh() {
      this.multipleSelection = []
      this.$emit('refresh')
    },
    handleShowFilter() {
      this.$emit('show-filter')
    },
    /**
     * 处理表格多选
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val
      // console.log('----multipleSelection--------', val)
      // this.$emit('update:multipleSelection', val)
    },
    handleTableCurrentChange(currentRow, oldCurrentRow) {
      if (this.showSelection && this.selectionType === 'radio') {
        this.multipleSelection = [currentRow]
        this.radioSelection = currentRow._key.value
      }

      // console.log('----multipleSelection--------',currentRow,oldCurrentRow)
    },
    handleCancel(){
      Quick.api.close('cancel', []);
    },
    handleConfirm() {
      let values = [];
      this.multipleSelection.forEach((item) => {

        if('_select_data' in item){
          values.push(item._select_data.value)
        }else{
          let obj = {}
          for (let key in item) {
            obj[key] = item[key].value
          }
          values.push(obj)
        }

      })
      Quick.api.close('submit', values);
    }
  }
}
</script>

<style lang="scss" scoped>


.table-header {
  margin-bottom: 15px;
}

.table-tools {
  margin-bottom: 15px;
}

.margin-s-l {
  margin-left: 10px;
}

.margin-s-r {
  margin-right: 10px;
}
</style>
